.profile-image-wrapper {
    position: relative;
    overflow: hidden;
    width: 16vw;
    height: 16vw;
}

.profile-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid #dcdcdc;
    object-fit: cover;
}

.btn-upload {
    position: absolute;
    bottom: 10%;
    right: 10%;
    color: white !important;
    background-color: #E86669;
    border-color: #E86669;
    font-size: 1.2em;
    width: 2.5em;
    height: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.btn-profile {
    background-color: #F47274 !important;
    color: #fff !important;
    border-radius: 25px;
    /* padding: 0.5rem 1.75rem; */
    padding: 0.5rem;
    width: -webkit-fill-available;
}

.form-select {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 10px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    appearance: none;
    line-height: 2;
}

.form-select:focus {
    border-color: #ced4da;
    outline: 0;
    box-shadow: none;
}

.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #ced4da;
    outline: 0;
    box-shadow: none;
}

input[type="date"] {
    position: relative;
    padding: 0.375rem 0.75rem;
}


@media (max-width:500px) {
    .btn-upload {
        position: absolute;
        bottom: 10%;
        right: 10%;
        color: white !important;
        background-color: #E86669;
        border-color: #E86669;
        font-size: 1.2em;
        width: 2.5em;
        height: 2.5em;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }

    .profile-image-wrapper {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: auto;
    }

}