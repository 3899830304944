/*=============================================================
                               FAQ
===============================================================*/

.section3 {
    padding-top: 95px;
    padding-bottom: 100px;
    background-color: rgb(11, 11, 11);
    font-family: sans-serif;
    letter-spacing: 0.02em;
}

.FAQ h1 {
    color: rgb(234, 102, 102);
    font-weight: 700;
}


.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #495057;
    background-color: none;
    border-color: none;
}


#faq {
    background: #fff;
    background-size: cover;
    overflow: hidden;
    position: relative;
    color: #000;
    padding: 60px 0 40px 0;
}

#faq h2 {
    color: #000;
    font-family: Poppins;
    font-size: 3vw;
    font-style: normal;
    font-weight: 700;
    /* line-height: 85px; */
    letter-spacing: 1px;
}

/* a:hover
a:active, 
a:focus 
{
    color: #E86669 !important;
    outline: none;
    text-decoration: none;
} */

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    border-bottom: 3px solid #d9d9d9;
}

.nav-pills .nav-link {
    background: #f5f5f5;
    border: 0;
    border-radius: 20px 20px 0px 0px;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: #000;
    text-decoration: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
    border-radius: 20px 20px 0px 0px;
    background: #F5F5F5;
    font-size: 2vw;
    font-weight: 600;
}

/* .nav-link :hover {
    color: #F47274 !important;
} */

/* .nav-link:focus,  */
.nav-link:hover {
    color: #000 !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    font-size: 2vw;
    font-weight: 600;
    color: #fff;
    border-radius: 20px 20px 0px 0px;
    background: #F47274;
}





/*=============================================================
                           Pills
===============================================================*/






/* .accordion-body {
    padding: 1rem 1.25rem;
    
    background-color: #2d2d2d;
} */



.Accor_head h6 {
    color: #000;
    font-weight: 600;
}

.Accor_head p {
    color: #000;
}

.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 1.25rem;
    font-size: 1.3vw;
    font-weight: 600;
    color: #000;
    text-align: left;
    background-color: #fff;
    /* border: 1px solid #cdcdcd !important; */
    border: none;
    border-radius: 0;
    overflow-anchor: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
}

.accordion-button:hover {
    z-index: 2;
    color: #E86669;
}


.accordion-item {
    background-color: #000;
    border: 1px solid #cdcdcd;
    border-radius: 0px;
}

.accordion-item:not(:first-of-type) {
    border-top: 1px solid #cdcdcd;
}

.accordion-button:not(.collapsed) {
    color: #E86669;
    background-color: #fff;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}


.accordion-button:focus {
    z-index: 3;
    border-color: white;
    outline: 0;
    box-shadow: none;
}


.accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ff6666'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out;
}

.accordion-button:not(.collapsed)::after {
    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ff6666'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
    transform: rotate(-180deg);
    /* color: white !important; */
}





.tab-content {
    padding: 30px;
}

.Accor_head {
    background-color: #fff !important;
}

.Accor_bd {
    background-color: #fff;
}

table, thead, tbody, th, td {
    border: 1px solid #cdcdcd !important;
    border-collapse: collapse !important;
}

table th {
    background-color: beige !important;
    color: #000;
}

.table-bordered {
    color: white;
    /* border-color: #686868 !important; */
    background-color: #111;
    border: 1px solid #cdcdcd !important;
}

.table_border {
    border: 1px solid #cdcdcd;
    /* color: #686868; */
}

/* .body-text-color{
    color: white !important;
} */
.table_border tr td {
    /* border-right: 1px solid #cdcdcd; */
    color: #000;
    text-align: center;
}



.table_border tr {
    text-align: center;
    /* border-right: 1px solid #686868; */
    color: white;
}


.Table_head {
    width: 50%;
    /* border-right: 1px solid #686868 !important; */
}

.table-bordered thead {
    color: white;
    border-color: #cdcdcd !important;
    /* border: 2px solid white; */
    /* background-color: #202124; */
    border-top: 1px solid #cdcdcd !important;
}

.table-bordered tbody {
    color: #000 !important;
}

.bg_color {
    background-color: #fff;
}


@media (max-width: 575.98px) {

    .Table {
        font-size: 12px;
    }

    table tr td {
        font-size: 12px;
    }

    .accordion-button {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 1rem 1.25rem;
        font-size:15px;
        font-weight: 600;
        color: #000;
        text-align: left;
        background-color: #fff;
        /* border: 1px solid #cdcdcd !important; */
        border: none;
        border-radius: 0;
        overflow-anchor: none;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
    }
    #faq h2{
        font-size: 21px;
    }
}