#ordersList .search {
    position: relative;
}

#ordersList .card {
    padding: 0;
}

#ordersList .search .search-input {
    background-color: #fff;
    height: 46px;
    width: 100%;
    border-radius: 2rem;
    border: 1px solid #cdcdcd;
    color: #666;
    font-size: 16px;
    text-indent: 10px;
    padding: 0px 15px 0px 45px;
}

#ordersList .search i {
    position: absolute !important;
    color: #666;
    left: 20px !important;
    top: 16px !important;
    width: 18px !important;
    height: 18px !important;
    font-size: 16px !important;
}

#ordersList .badgeDispatch {
    background-color: #F6ECD0;
    font-size: 16px;
    font-weight: 500;
    padding: 0.5rem 0.75rem;
    width: 80%;
    text-transform: uppercase;
}

#ordersList .badgeInit {
    background-color: #DCDCDC;
    font-size: 16px;
    font-weight: 500;
    padding: 0.5rem 0.75rem;
    width: 85%;
    text-transform: uppercase;
}

#ordersList .badgeComp {
    background-color: #5AE5CC;
    font-size: 16px;
    font-weight: 500;
    padding: 0.5rem 0.75rem;
    width: 80%;
    text-transform: uppercase;
}

#ordersList table,
thead,
tbody,
th,
td {
    border: none !important;
}

#ordersList table thead {
    background-color: #F5F5F5 !important;
    color: #000;
    border-radius: 10px;
    /* line-height: 40px; */
}

#ordersList table th {
    background: none !important;
    color: #000;
}

.table-bordered,
td,
th,
tr {
    border-color: inherit;
    border-style: solid;
    border-width: 1px;
    vertical-align: middle;
    white-space: nowrap;
    padding: 0.7rem 0;
}

/* #ordersList 
.table th:nth-child(4),
.table td:nth-child(4),
.table th:nth-child(5),
.table td:nth-child(5),
.table th:nth-child(6),
.table td:nth-child(6) {
    width: 15%;
} */

/* #ordersList table tbody td:nth-child(4), 
.table tbody td:nth-child(5), 
.table tbody td:nth-child(6),
.table tbody td:nth-child(7), 
.table tbody td:nth-child(8),
.table tbody td:nth-child(9) {
    width: 15% !important;
} */

#ordersList .btn-locker {
    color: #FFF;
    background-color: #F47274;
    font-family: Poppins;
    font-size: 1.25vw;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    border: 2px solid #F47274;
    border-radius: 50px;
    padding: 4px 60px;
    transition: 0.4s;
}

#ordersList .btn-locker:hover {
    background-color: #E86669;
    color: #FFF;
}

#ordersList .btn-ninja {
    color: #F47274;
    background-color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    border: 2px solid #F47274;
    border-radius: 50px;
    /* padding: 4px 60px; */
    transition: 0.4s;
    width: 80%;
    text-transform: uppercase;
}

#ordersList .btn-ninja:hover {
    background-color: #f47274;
    color: #fff;
}

#ordersList .btn-submit {
    background-color: #F47274 !important;
    color: #fff !important;
    border-radius: 10px;
    padding: 0.375rem 1.75rem;
}

#ordersList .btn-light {
    text-decoration: underline;
    border-radius: 10px;
    /* padding: 0.375rem 1.75rem; */
}

#ordersList .btn-status {
    color: #000 !important;
    background-color: #F2F2F2;
    font-family: Poppins;
    font-size: 1vw;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    border: 2px solid #DCDCDC;
    border-radius: 50px !important;
    padding: 6px 36px;
    transition: 0.4s;
}

#ordersList .btn-status:hover {
    background-color: lightgray;
    color: #000;
}

#ordersList .btn-status::after {
    display: inline-block !important;
    margin-left: 0.7em !important;
    vertical-align: 0.1em !important;
    border-top: 0.7em solid !important;
    /* Size of the arrow */
    border-right: 0.4em solid transparent !important;
    /* Right side of the arrow */
    border-left: 0.4em solid transparent !important;
    /* Left side of the arrow */
    border-bottom: 0;
    /* Hide the bottom to create the arrow shape */
    color: #F47274;
    /* Change this to the desired color */
}

#ordersList .fa-regular,
.far {
    font-family: "Font Awesome 6 Free";
    font-weight: 400;
    color: #F47274;
}

#ordersList .form-check {
    display: block;
    min-height: auto;
    display: flex;
    justify-content: center;
}

@media (max-width: 1500px) {
    #ordersList .badgeInit {
        background-color: #DCDCDC;
        font-size: 16px;
        font-weight: 500;
        padding: 0.5rem 0.75rem;
        width: 100% !important;
        text-transform: uppercase;
    }
}